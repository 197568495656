// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../assets/images/arrow-right.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comp_poetry_readings{padding:0 15px;background:#fff;box-shadow:0 0 3px 0 rgba(0,1,0,.1);border-radius:6px}.comp_poetry_readings .external_poetry_item{margin-top:20px;width:100%;background-color:#fff;display:-webkit-flex;display:flex;-webkit-flex-wrap:nowrap;flex-wrap:nowrap;-webkit-justify-content:space-between;justify-content:space-between}.comp_poetry_readings .external_poetry_item .external_poetry_wrapper{width:95%;display:-webkit-flex;display:flex;-webkit-flex-wrap:nowrap;flex-wrap:nowrap;padding:15px 0}.comp_poetry_readings .external_poetry_item .external_poetry_wrapper .img_wrapper{position:relative;width:55%;display:-webkit-flex;display:flex;-webkit-align-items:center;min-height:67.5px;max-width:120px;align-items:center;min-width:120px}.comp_poetry_readings .external_poetry_item .external_poetry_wrapper .img_wrapper img{width:100%;height:auto;max-height:67.5px;max-width:120px}.comp_poetry_readings .external_poetry_item .external_poetry_wrapper .peotry_info{display:-webkit-flex;display:flex;-webkit-flex-direction:column;flex-direction:column;-webkit-justify-content:space-around;justify-content:space-around;padding:8px 0 8px 10px}.comp_poetry_readings .external_poetry_item .external_poetry_wrapper .peotry_info .peotry_title{line-height:1.2;color:#756855;font-size:16px;font-weight:700}.comp_poetry_readings .external_poetry_item .external_poetry_wrapper .peotry_info .author_dynasty{margin-top:3px;font-size:14px;color:#756855;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;word-break:break-all}.comp_poetry_readings .external_poetry_item .arrow_left{right:0;width:8px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:8px 15px;background-position:50%}.comp_poetry_readings:first-child .external_poetry_item{margin-top:0}.comp_poetry_readings:last-child .external_poetry_item{border-bottom:none!important}", ""]);
// Exports
module.exports = exports;
