/* eslint-disable react/prop-types */
import React, { Component, useMemo, useState } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import ReactMarkdown from "react-markdown";
import { request, csrfHeaders } from "../utils/request";
import { GA, GA_new } from "../utils/Env.js";
import StudyReportInfo from "../utils/study_report_info/StudyReportBar";
import ReportHeaderDay from "../utils/study_report_info/ReportHeaderDay";
import { UserStudyReportQuizList } from "../day_study_reports/StudyReport";
import DayStudyExam from "../utils/study_report_info/DayStudyExam";
import AudioPlayer from "../utils/audio_player/AudioPlayer";
import { PrinterPracticeNote, PdfPracticeNoteCopy } from "./StudyReport.jsx";
import { StudyPackageCommentModule } from "../study_packages/UnitModeStudyPackage.jsx";
import UnlockAllPoetryGuide from "../../components/study_packages/poetries/v2/components/UnlockAllPoetryGuide.jsx";
import RightFloatIcons from "../study_package_activities/RightFloatIcons.jsx";
import ContinuousLearningFlag from "./ContinuousLearningFlag.jsx";
import { clipboard, showDialog } from "../utils/helpers.js";
import { ToastManager } from "../utils/Toast";
import { SharePosterProxy } from "../promotion_activities/SharePoster";
import RewardExchangeProgress from "../point_reward/RewardExchangeProgress";
import {
  Photos,
  Photo,
  StyledUploadTip,
  StyledCommentBroad,
  ReviewedBroad,
} from "../study_packages/poetries/v2/Reading.jsx";
import QuickBack from "../utils/quick_back/QuickBack";
import LazyloadContent from "../utils/lazyload/LazyloadContent";
import { SingleCourseBanner, MultCourseBanner } from "../banner/Banner.jsx";

import icon_star_0 from "study_package/icon_star_0.png";
import icon_star_1 from "study_package/icon_star_1.png";
import icon_star_2 from "study_package/icon_star_2.png";
import icon_star_3 from "study_package/icon_star_3.png";
import topicName from "topic_name.png";
import arrowUp from "arrow-up.png";
import arrowDown from "arrow-down.png";

import "../utils/ReactMarkDown.scss";
import "./PoetryReport.scss";
import "../day_study_reports/UserStudyReportQuizList.scss";
import "./StepByStepExercise.scss";
import ReadingReport from "../reading_files/reading_report/ReadingReport";

const ReadingStudyReportList = ({
  name,
  subName,
  babyName,
  mediaLessons,
  todayQuiz,
  poetryDetailClick,
  handoutsDescClick,
  iconStars,
  listF,
  listH,
  mediaConclusion,
  isSpell,
  isSanZiJing,
  studyType,
  isBeforeDeadline,
  commentAudioUrl,
  commentAudioInfo,
  commentText,
  answer,
  answers,
  courseConsultant,
  topic,
  without_comment,
}) => {
  const isReviewed = !!commentAudioUrl;
  const reviewedPhotos = useMemo(() => {
    if (answers?.length > 0) {
      if (isReviewed) {
        return answers.map((a) => a.comment_photo_url);
      } else {
        return answers.map((a) => a.answer_photo_url);
      }
    }
    return [];
  }, [answers, isReviewed]);

  const photos = (
    <Photos style={{ marginBottom: 30, marginTop: 13 }}>
      {!!reviewedPhotos.length &&
        reviewedPhotos.map((photo, index) => (
          <Photo key={index} file={photo} index={index} isReviewed isUploaded />
        ))}
    </Photos>
  );

  const renderReviewed = () => {
    return (
      <>
        {!without_comment &&
          <div
            className="report_knowledge_describe"
            style={{ paddingBottom: 20 }}
          >
            <div className="knowledge_title" onClick={poetryDetailClick}>
              <img src={!listF ? arrowUp : arrowDown} alt="" />
              <h3>老师评语</h3>
            </div>
            {listF ? (
              ""
            ) : (
              <ReviewedBroad
                reviewedPhotos={reviewedPhotos}
                commentAudioUrl={commentAudioUrl}
                commentAudioInfo={commentAudioInfo}
                commentText={commentText}
                teacherPhoto={courseConsultant.photo_url}
                teacherName={courseConsultant.name}
              />
            )}
          </div>
        }
        <div className="report_knowledge_describe">
          <div className="knowledge_title" onClick={handoutsDescClick}>
            <img src={!listH ? arrowUp : arrowDown} alt="" />
            <h3>答案解析</h3>
          </div>
          {listH ? (
            ""
          ) : (
            <StyledCommentBroad>
              <div className="markdown_content teacher_comment_broad">
                {answer && <ReactMarkdown source={answer} />}
              </div>
            </StyledCommentBroad>
          )}
        </div>
      </>
    );
  };

  const renderNoneReviewed = () => {
    return (
      <>
        <div className="report_knowledge_describe">
          <div className="knowledge_title" onClick={poetryDetailClick}>
            <img src={!listF ? arrowUp : arrowDown} alt="" />
            <h3>我的回答</h3>
          </div>
          {listF ? "" : photos}
        </div>
        {/* <div className="report_knowledge_describe">
          <div className="knowledge_title" onClick={handoutsDescClick}>
            <img src={!listH ? arrowUp : arrowDown} alt="" />
            <h3>{isBeforeDeadline ? "老师评语" : "答案解析"}</h3>
          </div>
          {listH ? (
            ""
          ) : isBeforeDeadline ? (
            <StyledUploadTip style={{ marginTop: 12 }}>
              老师将在作业上传后2个工作日内点评作业，并公布正确答案
            </StyledUploadTip>
          ) : (
            <StyledCommentBroad>
              <div className="markdown_content teacher_comment_broad">
                {answer && <ReactMarkdown source={answer} />}
              </div>
            </StyledCommentBroad>
          )}
        </div> */}
        {isBeforeDeadline && !without_comment ? 
        (<div className="report_knowledge_describe">
          <div className="knowledge_title" onClick={handoutsDescClick}>
            <img src={!listH ? arrowUp : arrowDown} alt="" />
            <h3>{isBeforeDeadline ? "老师评语" : "答案解析"}</h3>
          </div>
          {listH ? (
            ""
          ) : isBeforeDeadline ? (
            <StyledUploadTip style={{ marginTop: 12 }}>
              老师将在作业上传后2个工作日内点评作业，并公布正确答案
            </StyledUploadTip>
          ) : (
            <StyledCommentBroad>
              <div className="markdown_content teacher_comment_broad">
                {answer && <ReactMarkdown source={answer} />}
              </div>
            </StyledCommentBroad>
          )}
        </div>) : ""}
        <div className="report_knowledge_describe">
          <div className="knowledge_title" onClick={handoutsDescClick}>
            <img src={!listH ? arrowUp : arrowDown} alt="" />
            <h3>答案解析</h3>
          </div>
          {listH ? (
            ""
          ) : (
            <StyledCommentBroad>
              <div className="markdown_content teacher_comment_broad">
                {answer && <ReactMarkdown source={answer} />}
              </div>
            </StyledCommentBroad>
          )}
        </div>
      </>
    );
  };

  const [isCloseConclusion, setCloseConclusion] = useState(false);
  const [isCloseQuizzes, setCloseQuizzes] = useState(false);

  return (
    <div className="study_report_list" style={{ padding: "15px 5px" }}>
      {topic && (
        <span
          className="topic_name"
          style={{ backgroundImage: `url(${topicName})` }}
        >
          {topic}
        </span>
      )}
      <div className="sub_name">《{name}》</div>
      <div className="study_report_content">
        {
          <>
            {mediaLessons && mediaLessons.length > 0 && (
              <div className="course_info">
                <div className="report_state">
                  {mediaLessons.map((mediaLesson) => (
                    <div key={mediaLesson.id}>
                      <div className="report_state_result">
                        <img
                          className="lazyload"
                          data-src={iconStars[mediaLesson.rank]}
                        />
                        <h3>{mediaLesson.name}</h3>
                      </div>
                      {mediaLesson.first_record_url && (
                        <AudioPlayer
                          name={name}
                          babyName={babyName}
                          entireRecordUrl={mediaLesson.first_record_url}
                        />
                      )}
                      {mediaLesson.entire_record_url && (
                        <AudioPlayer
                          name={isSpell ? `${name}儿歌` : `${name}`}
                          babyName={babyName}
                          entireRecordUrl={mediaLesson.entire_record_url}
                        />
                      )}
                    </div>
                  ))}
                  {todayQuiz && (
                    <div className="report_state_result">
                      <img
                        className="lazyload"
                        data-src={iconStars[todayQuiz.rank]}
                      />
                      <h3>随堂练习</h3>
                    </div>
                  )}
                </div>
              </div>
            )}

            {todayQuiz && todayQuiz.quizzes && todayQuiz.quizzes.length > 0 && (
              <div className="game_detail">
                <div
                  className="knowledge_title"
                  onClick={() => setCloseQuizzes((pre) => !pre)}
                >
                  <img src={!isCloseQuizzes ? arrowUp : arrowDown} alt="" />
                  <h3>{studyType === 0 ? "随堂练习" : "阅读闯关"}详情</h3>
                </div>
                <div>
                  {isCloseQuizzes ? (
                    ""
                  ) : (
                    <div className="practice_result_content">
                      <div className="practice_report_box">
                        {todayQuiz.quizzes.map((quiz) => (
                          <UserStudyReportQuizList key={quiz.id} quiz={quiz} />
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {mediaConclusion && (
              <div className="report_knowledge_describe">
                <div
                  className="knowledge_title"
                  onClick={() => setCloseConclusion((pre) => !pre)}
                >
                  <img src={!isCloseConclusion ? arrowUp : arrowDown} alt="" />
                  <h3>讲义</h3>
                </div>
                {isCloseConclusion ? (
                  ""
                ) : (
                  <div className="knowledge_desc_content">
                    <LazyloadContent content={mediaConclusion} />
                  </div>
                )}
              </div>
            )}
          </>
        }

        {studyType === 1 && (
          <div className="reading_day_2 ">
            {!isReviewed && renderNoneReviewed()}
            {isReviewed && renderReviewed()}
          </div>
        )}
      </div>
    </div>
  );
};

const StudyReportList = ({
  name,
  subName,
  babyName,
  mediaLessons,
  todayQuiz,
  poetryDetailClick,
  handoutsDescClick,
  iconStars,
  listF,
  listH,
  mediaConclusion,
  isSpell,
  isSanZiJing,
}) => {
  return (
    <div className="study_report_list">
      <div className="sub_name">
        《{name}》{subName}
      </div>
      <div className="study_report_content">
        <div className="course_info">
          <div className="report_state">
            {mediaLessons &&
              mediaLessons.map((mediaLesson) => (
                <div key={mediaLesson.id}>
                  <div className="report_state_result">
                    <img
                      className="lazyload"
                      data-src={iconStars[mediaLesson.rank]}
                    />
                    <h3>{mediaLesson.name}</h3>
                  </div>
                  {mediaLesson.first_record_url && (
                    <AudioPlayer
                      name={name}
                      babyName={babyName}
                      entireRecordUrl={mediaLesson.first_record_url}
                    />
                  )}
                  {mediaLesson.entire_record_url && (
                    <AudioPlayer
                      name={isSpell ? `${name}儿歌` : `${name}`}
                      babyName={babyName}
                      entireRecordUrl={mediaLesson.entire_record_url}
                    />
                  )}
                </div>
              ))}
            {todayQuiz && (
              <div className="report_state_result">
                <img
                  className="lazyload"
                  data-src={iconStars[todayQuiz.rank]}
                />
                <h3>{isSpell || isSanZiJing ? "练一练" : "诗词游戏"}</h3>
              </div>
            )}
          </div>
        </div>

        <div className="game_detail">
          <div className="knowledge_title" onClick={poetryDetailClick}>
            <img src={!listF ? arrowUp : arrowDown} alt="" />
            <h3>{isSpell || isSanZiJing ? "练一练" : "诗词游戏"}详情</h3>
          </div>
          <div>
            {listF ? (
              ""
            ) : (
              <div className="practice_result_content">
                <div className="practice_report_box">
                  {todayQuiz.quizzes.map((quiz) => (
                    <UserStudyReportQuizList key={quiz.id} quiz={quiz} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="report_knowledge_describe">
          <div className="knowledge_title" onClick={handoutsDescClick}>
            <img src={!listH ? arrowUp : arrowDown} alt="" />
            <h3>讲义</h3>
          </div>
          {listH ? (
            ""
          ) : (
            <div className="knowledge_desc_content">
              <LazyloadContent content={mediaConclusion} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default class PoetryReport extends Component {
  constructor(props) {
    super(props);
    console.log("PoetryReport-props--------------------", props);

    let objR = new Object();
    !this.props.day_study_exams
      ? ""
      : this.props.day_study_exams.forEach(function (item) {
          !item.quizzes
            ? ""
            : item.quizzes.forEach(function (im) {
                objR[im.id] = true;
              });
        });

    let mediaConclusion = [];
    let mediaLessonConclusion;
    !this.props.media_lessons
      ? ""
      : this.props.media_lessons.forEach(function (item) {
          mediaConclusion.push(item.media_conclusion);
          mediaLessonConclusion = mediaConclusion.join("");
        });

    this.state = {
      day: this.props.day,
      prev_day_url: this.props.prev_day_url,
      next_day_url: this.props.next_day_url,
      day_study_exams: this.props.day_study_exams,
      review_media_lessons: this.props.review_media_lessons,
      study_report: this.props.study_report,
      objF: false,
      objH: false,
      list: objR,
      mediaConclusion: mediaLessonConclusion,
      pdfPracticeUrlCopy: false,
    };

    this.poetryDetailClick = this.poetryDetailClick.bind(this);
    this.studyCardClick = this.studyCardClick.bind(this);
  }

  poetryDetailClick = (e) => {
    e.preventDefault();
    this.state.objF = !this.state.objF;
    this.setState({
      objF: this.state.objF,
    });
  };

  handoutsDescClick = (e) => {
    e.preventDefault();
    this.state.objH = !this.state.objH;
    this.setState({
      objH: this.state.objH,
    });
  };

  // 打卡生成海报
  studyCardClick = () => {
    // package_mode 1 新版， 前端生成海报
    if (this.props.package_mode === 1) {
      const {
        poster_photo_template_url: posterTemplate,
        poster_creation: posterCreation,
      } = this.props.checkin_poster;

      new SharePosterProxy({
        posterTemplate,
        posterCreation,
        mode: "front_end",
        free: "poetry_share",
        nickName: this.props.baby.nickname,
        isNewPoetry: true,
      }).open();
      return;
    }

    if (this.props.poster_url) {
      new SharePosterProxy({
        posterUrl: this.props.poster_url,
        free: "invite_collect_star",
        nickName: this.props.baby.nickname,
        ruleUrl: this.props.rule_url,
      }).open();
    }
  };

  handleCopyUrl = (e) => {
    const parentIndex = e.currentTarget.getAttribute("data-index");
    // console.log('parentIndex: ', parentIndex);

    let currentClickUrl =
      parentIndex == -1
        ? this.props.unit_pdf_url
        : this.props.media_lessons[parentIndex].media_lesson_pdf_url;

    // console.log("parentIndex: ", parentIndex);
    // console.log("currentClickUrl: ", currentClickUrl);

    clipboard(window, document, navigator).copy(currentClickUrl, true);
  };

  handlePdfPractice = (boolState) => {
    this.setState({
      pdfPracticeUrlCopy: boolState,
    });
  };

  // 点击“写评价” 控制显示
  getWriteEvaluateStatus = () => {
    // console.log(this.props, "0000000000000000000");
    GA("shortcut", "packageComment", this.props.study_package_activity_id);
    GA_new(
      "shortcut",
      "package_comment",
      this.props.study_package_activity_id,
      this.props.target_type,
      this.props.target_id
    );
    this.setState({
      writeEvaluateDialogState: true,
    });
    // GA("shortcut", "reportComment", this.props.study_package_activity_id);
  };

  // 关闭评价弹框
  hideWriteEvaluateState = () => {
    this.setState({
      writeEvaluateDialogState: false,
    });
  };

  onBackIconClick = () => {
    // console.log(this.props, "0000000000000000000");
    GA("shortcut", "reportback", this.props.study_package_activity_id);
    GA_new(
      "shortcut",
      "report_back",
      this.props.study_package_activity_id,
      this.props.target_type,
      this.props.target_id
    );
    window.location.href = this.props.study_package_url;
    sessionStorage.clear();
  };

  getCreateRecommendRequestUrl = () => {
    this.setState({
      createRecommendRequestUrl: "",
    });
  };

  onInvitationIconClick = () => {
    const {
      get_shopping_group_data_url,
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
      study_package_activity_id,
    } = this.props;
    const toast = ToastManager.showLoading("海报加载中...");
    request({
      method: "GET",
      url: get_shopping_group_data_url,
      headers: csrfHeaders,
    })
      .then((res) => res.data)
      .then(({ bonus, had_joined_groupon, remaining_shopping_group_count }) => {
        new SharePosterProxy({
          // posterUrl: this.props.poster_url,
          // posterImgSrc: this.props.poster_img_src,
          posterTemplate,
          posterCreation,
          mode: "front_end",
          free:
            had_joined_groupon && remaining_shopping_group_count > 0
              ? "in_or_open_shopping_group"
              : "none_shopping_group",
          bonus: bonus,
          sale_mode: "shopping_group",
          remaining_shopping_group_count,
        })
          .open({
            isShowToast: false,
          })
          .then(() => {
            toast.cancel();
          });
      })
      .catch((err) => {
        toast.cancel();
        const dialogOptions = {
          text: "生成海报出错，请重新尝试",
          mainLabel: "确定",
          subLabel: "取消",
          mainCallback: () => {
            this.onInvitationIconClick();
          },
          subCallback: () => {},
        };
        showDialog.call(this, dialogOptions);
        console.log(err);
      });

    GA("shortcut", "reportPoster", study_package_activity_id);
    GA_new(
      "shortcut",
      "report_poster",
      study_package_activity_id,
      this.props.target_type,
      this.props.target_id
    );
  };

  onAutoOpenPoster = () => {
    const urlQuery = queryString.parse(window.location.search);
    const ele = $("#poster_card");
    if (urlQuery.show_poster === "true" && ele.length) {
      ele.trigger("click");
    }
  };

  componentDidMount() {
    // 自动打开海报
    if (!this.props.is_spell) {
      this.onAutoOpenPoster();
    }
    if (this.props.share) {
      const {
        share_title,
        share_url,
        share_desc,
        share_image_url,
      } = this.props.share;
      if (share_title) {
        wx.ready(function () {
          function on_share_success() {}
          var sharedJson = {
            title: share_title,
            link: share_url,
            desc: share_desc,
            imgUrl: share_image_url,
            success: on_share_success,
          };
          wx.onMenuShareTimeline(sharedJson);
          wx.onMenuShareAppMessage(sharedJson);
        });
      }
    }
  }

  render() {
    const {
      list,
      day_study_exams,
      review_media_lessons,
      day,
      prev_day_url,
      next_day_url,
      mediaConclusion,
      pdfPracticeUrlCopy,
      writeEvaluateDialogState,
    } = this.state;
    const {
      questionnaire_tip,
      questionnaire_url,
      baby,
      media_lessons,
      study_report,
      answers,
      name,
      sub_name,
      today_quiz,
      studied_course_day_count,
      course_day_count,
      studied_media_courses_count,
      media_courses_count,
      media_lessons_count,
      study_package_url,
      course_type,
      study_package_activity_code,
      unit_name,
      unit_pdf_url,
      upgrade_package,
      upgrade_package_required,
      continue_study_reward,
      is_spell,
      sub_course_type,
      total_studied_media_lessons_count,
      isSanZiJing = sub_course_type === "sanzijing",
      isReading = sub_course_type === "reading",
      study_type,
    } = this.props;
    const buyUrl =
      upgrade_package && upgrade_package.study_package_activity_buy_url;
    const icon_stars = [icon_star_0, icon_star_1, icon_star_2, icon_star_3];

    const lessonPdfUrlBool =
      media_lessons &&
      media_lessons.length > 0 &&
      media_lessons
        .map((lesson) => lesson.media_lesson_pdf_url)
        .some((url) => typeof url == "string");

    return (
      <div
        className="comp_poetry_report"
        style={{
          backgroundColor:
            this.props.study_mode == "reading_v2" && study_report
              ? "#fff3e8"
              : "",
          paddingBottom:
            this.props.study_mode == "reading_v2" && study_report
              ? this.props.study_topic_report_url
                ? 121
                : 50
              : "",
        }}
      >
        <ReportHeaderDay
          prevDayUrl={prev_day_url}
          day={day}
          nextDayUrl={next_day_url}
        />

        {/* {!UserAgent.isWechat() && (
          <QuickBack courseListUrl={study_package_url} />
        )} */}
        {sub_course_type == "reading" &&
        this.props.study_mode &&
        this.props.study_mode == "reading_v2" &&
        study_report ? (
          <ReadingReport {...this.props} {...this.state}></ReadingReport>
        ) : study_report ? (
          <>
            {questionnaire_tip && (
              <div className="questionnaire-tips">
                <div className="q-text">活跃用户小调研</div>
                <div className="q-btn">
                  <a href={questionnaire_url}>开始</a>
                </div>
              </div>
            )}
            {upgrade_package_required && (
              <UnlockAllPoetryGuide
                url={`${buyUrl}&ref=unlock_poetry_report`}
              />
            )}
            {!upgrade_package_required && (
              <StudyReportInfo
                studiedCourseDayCount={studied_course_day_count}
                courseDayCount={course_day_count}
                leftSectionName={"已学天数"}
                totalStudiedMediaLessonsCount={
                  is_spell || isSanZiJing
                    ? total_studied_media_lessons_count
                    : studied_media_courses_count
                }
                mediaLessonsCount={
                  is_spell || isSanZiJing
                    ? media_lessons_count
                    : media_courses_count
                }
                rightSectionName={
                  is_spell || isSanZiJing ? "已学课时" : "已学古诗"
                }
                reward={this.props.reward}
                course_type={this.props.course_type}
                isReading={isReading}
                studied_tags_count={this.props.studied_tags_count || 0}
                total_tags_count={this.props.total_tags_count || 0}
                currentStudyModule={this.props.current_study_module || 0}
                currentStudyModule={this.props.current_study_module}
                readingArticlesCount={this.props.read_articles_count}
                readingWordsCount={this.props.read_words_count}
              />
            )}

            {this.props.reward_commodity && this.props.exchange_commodities.commodities.length > 0 && <div style={{marginBottom: '20px', marginTop: '20px'}}>
              <RewardExchangeProgress
                commodity={this.props.reward_commodity}
                totalPoint={this.props.total_point}
                create_reward_exchange_url={this.props.create_reward_exchange_url}
                study_package_activity_id={this.props.study_package_activity_id}
                className="without-border"
                list={this.props.exchange_commodities.commodities}
                selected={this.props.exchange_commodities.selected}
                selectRewardPath={this.props.select_reward_commodity_path}
                commoditiesPath={this.props.commodities_path}
                maxRewardPoints={this.props.max_reward_points}
              />
            </div>}

            {continue_study_reward && (
              <ContinuousLearningFlag
                course_type={course_type}
                baby_name={baby.nickname}
                study_day={continue_study_reward.continue_studied_days}
                url={continue_study_reward.continue_study_reward_url}
              />
            )}
            {!day_study_exams
              ? ""
              : day_study_exams.map((day_study_exam, index) =>
                  !("score" in day_study_exam) ? (
                    ""
                  ) : (
                    <DayStudyExam
                      key={index}
                      list={list}
                      index={index}
                      dayStudyExam={day_study_exam}
                      courseType={course_type}
                    />
                  )
                )}
            {review_media_lessons && review_media_lessons.length > 0 && (
              <div className="review_media_lesson">
                {!is_spell && <div className="title_head">唤醒</div>}
                {review_media_lessons.map((media_lesson, index) => (
                  // !("rank" in media_lesson) ? '' :
                  <div className="media_lesson_content" key={index}>
                    <div className="lesson_subject_head">
                      <img
                        className="lazyload"
                        data-src={icon_stars[media_lesson.rank]}
                        alt=""
                      />
                      <p>{media_lesson.meida_course.name}</p>
                    </div>
                    {media_lesson.entire_record_url && (
                      <AudioPlayer
                        name={media_lesson.meida_course.name}
                        babyName={baby.nickname}
                        entireRecordUrl={media_lesson.entire_record_url}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}

            {isReading && study_report && (
              <ReadingStudyReportList
                mediaLessons={media_lessons}
                todayQuiz={today_quiz}
                name={name}
                subName={sub_name}
                babyName={baby.nickname}
                poetryDetailClick={this.poetryDetailClick}
                handoutsDescClick={this.handoutsDescClick}
                iconStars={icon_stars}
                listF={this.state.objF}
                listH={this.state.objH}
                mediaConclusion={mediaConclusion}
                isSpell={is_spell}
                isSanZiJing={isSanZiJing}
                isReading={isReading}
                answers={answers}
                commentAudioUrl={this.props.comment_audio_url}
                commentText={this.props.comment_text}
                answer={this.props.answer}
                studyType={study_type}
                isBeforeDeadline={this.props.reminder_teacher_comment}
                courseConsultant={this.props.course_consultant}
                topic={this.props.topic}
                commentAudioInfo={this.props.comment_audio_info_url}
                without_comment={this.props.without_comment}
              />
            )}
            {!isReading && study_report && media_lessons.length > 0 && (
              <StudyReportList
                mediaLessons={media_lessons}
                todayQuiz={today_quiz}
                name={name}
                subName={sub_name}
                babyName={baby.nickname}
                poetryDetailClick={this.poetryDetailClick}
                handoutsDescClick={this.handoutsDescClick}
                iconStars={icon_stars}
                listF={this.state.objF}
                listH={this.state.objH}
                mediaConclusion={mediaConclusion}
                isSpell={is_spell}
                isSanZiJing={isSanZiJing}
              />
            )}
          </>
        ) : (
          ""
        )}
        {!study_report && !this.props.study_inprogress && (
          <div className="study_no_finish">
            完成当天课程的学习后，才可以查看学习报告哦 ～
          </div>
        )}

        {this.props.recommend_course && (
          <SingleCourseBanner recommendCourse={this.props.recommend_course} />
        )}
        {this.props.recommend_theme && (
          <MultCourseBanner recommendTheme={this.props.recommend_theme} />
        )}
        <div className="bottom_fixed">
          <div className="flex_wrapper">
            {/* {!isReading && !is_spell && study_report == true && (
              <div
                className="study_card"
                onClick={this.studyCardClick}
                id="poster_card"
              >
                打卡分享
              </div>
            )} */}

            {
              // 练习题pdf打印 (入口)
              lessonPdfUrlBool && (
                <PrinterPracticeNote
                  handlePdfPractice={this.handlePdfPractice}
                  isPoetry={true}
                />
              )
            }
          </div>
        </div>

        {!!writeEvaluateDialogState && (
          <StudyPackageCommentModule
            create_recommend_request_url={createRecommendRequestUrl}
            comment_target={this.props.comment_target}
            current_user_comment={this.props.current_user_comment}
            bought_phase={this.props.bought_phase}
            promotion={this.props.promotion}
            rate={this.props.rate}
            name={this.props.study_package_name}
            hideDialog={this.hideWriteEvaluateState}
            getCreateRecommendRequestUrl={this.getCreateRecommendRequestUrl}
          />
        )}
        {pdfPracticeUrlCopy && (
          <PdfPracticeNoteCopy
            media_lessons={media_lessons}
            handlePdfPractice={this.handlePdfPractice}
            copyUrl={this.handleCopyUrl}
            unitPdfUrl={unit_pdf_url}
            unitName={unit_name}
            activityCode={study_package_activity_code}
            isPoetry={true}
          />
        )}
        <RightFloatIcons
          isShowMakeComment={
            this.props.comment_state === "uncommented" &&
            !!createRecommendRequestUrl
          }
          bottomPosition={70}
          onMakeCommentClick={this.getWriteEvaluateStatus}
          isShowRedMoney={!!this.props.bonus}
          onRedMoneyClick={this.onInvitationIconClick}
          isShowBack={true}
          onBackClick={this.onBackIconClick}
          zIndexLevel={19}
        />
      </div>
    );
  }
}

PoetryReport.propTypes = {
  media_lessons: PropTypes.array,
};

PoetryReport.defaultProps = {
  free: "invite_collect_star",
};
