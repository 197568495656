// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../shared/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../shared/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../../assets/images/new_poetry/footer_unlock.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comp_unlocked_all_course_reminder .all_course_reminder{position:fixed;height:113px;width:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%;background-repeat:no-repeat;bottom:0;z-index:2}.comp_unlocked_all_course_reminder .all_course_reminder .flex_wrapper{width:100%;height:100%;display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center;position:relative}.comp_unlocked_all_course_reminder .all_course_reminder dl{margin-right:4px;color:#fff;position:absolute;bottom:5px;left:10px}.comp_unlocked_all_course_reminder .all_course_reminder dl dt{font-weight:700;padding-bottom:10px}.comp_unlocked_all_course_reminder .all_course_reminder dl dd{font-size:12px}.comp_unlocked_all_course_reminder .all_course_reminder .now_btn{display:block;width:80px;height:30px;background-color:#fff;color:#49c114;box-shadow:0 1px 5px 0 rgba(53,174,0,.5);border-radius:15px;line-height:30px;text-align:center;font-size:12px;font-weight:700;position:absolute;right:15px;bottom:15px}@media screen and (min-width:1024px){.comp_unlocked_all_course_reminder,.comp_unlocked_all_course_reminder .all_course_reminder{max-width:500px;margin:0 auto}}", ""]);
// Exports
module.exports = exports;
