import React, { useState } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import Word from "./Word";
import icon_R2_down from "R2/icon_R2_down.png";
import "./PracticeClass.scss";
import { InfoBroadTitle, Titles } from "../reading_details/ExaminationCommit";

import icon_play_selected from "@assets/images/icon_play_selected.gif";
import icon_play_normal from "@assets/images/icon_play_normal.png";

const QuizTitle = styled.div`
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 26px;
  position: relative;
  color: #333333;
  padding-left:30px;
  display:inline;
  white-space:pre-line;
  &::before {
    content: "${({ index }) => index}";
    position: absolute;
    left: 0;
    top: 4px;
    width: 18px;
    height: 18px;
    background: #ff973c;
    opacity: 1;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #ffffff;
  }
`;
const promisifiedPlayEffectAudio = (ElementId) => {
  console.log("------------", ElementId);
  return new Promise((resolve, reject) => {
    let audio = document.getElementById(ElementId);
    console.log("------------", audio);
    audio.play();
    setTimeout(() => {
      resolve();
    }, 500);
  });
};
export const QuizItem = ({ itm, inx }) => {
  console.log("QuizItem", itm);
  const [isClose, setIsClose] = useState(false);
  const [currentPlayId, setCurrentPlayId] = useState("");
  const renderMaterials = (materials) => {
    let str = "";
    materials.map((i, d) => {
      str = str + i.content;
    });
    return str;
  };
  const {
    subjective,
    input_correct,
    input_answer,
    input_answer_url,
    correct_answer_url,
    correct_answer,
    hint,
    id,
    materials,
    material_title,
    quiz_type: type,
    quiz_type,
  } = itm;
  return (
    <div
      className="quiz-item"
      style={{ marginTop: inx == 0 ? 0 : "" }}
      key={itm.id + "-" + inx}
    >
      <div className="quiz-question">
        <QuizTitle index={inx + 1}>{itm.question || ""}</QuizTitle>
        {/* <div className="question-index">{inx + 1}</div> */}
        {itm.question_body && (
          <div className="question-body" style={{ marginTop: 20 }}>
            {/* {itm.question_body} */}
            <ReactMarkdown
              source={itm.question_body}
              className="markdown_content"
              escapeHtml={false}
            ></ReactMarkdown>
          </div>
        )}
      </div>
      <>
        <div
          className="back_tari_course"
          style={{ marginTop: 5, paddingBottom: 5 }}
        >
          <div
            className="back_tari_course_connect_top"
            style={{
              borderRadius:
                hint && !input_correct ? "10px 10px 0px 0px " : "10px",
            }}
          >
            <div className="back_tari_course_connect_top_1">
              {(input_answer || input_answer_url) && (
                <div>
                  {"你的答案："}
                  {input_answer_url ? (
                    ""
                  ) : (
                    <div
                      style={{
                        display: "inline",
                        color: input_correct ? "#66D167" : "#FF6868",
                      }}
                    >
                      {input_answer}
                    </div>
                  )}

                  {!subjective && (
                    <span
                      style={{
                        color: input_correct ? "#66D167" : "#FF6868",
                        // fontSize: 16,
                      }}
                    >
                      {input_correct ? "（正确）" : "（错误）"}
                    </span>
                  )}
                  {type == 3 && input_answer_url ? (
                    <div
                      className="back_tari_course_connect"
                      onClick={() => {
                        setCurrentPlayId("error_" + id);
                        promisifiedPlayEffectAudio("error_" + id).then(() => {
                          setCurrentPlayId("");
                        });
                      }}
                    >
                      <img
                        className="icon_play_normal"
                        src={
                          currentPlayId == "error_" + id
                            ? icon_play_selected
                            : icon_play_normal
                        }
                      ></img>
                      <audio
                        id={"error_" + id}
                        src={input_answer_url}
                        preload="auto"
                      ></audio>
                    </div>
                  ) : (
                    ""
                  )}
                  {type == 2 && input_answer_url ? (
                    <div
                      className="back_tari_course_connect"
                      onClick={() => {
                        setCurrentPlayId("error_" + id);
                        promisifiedPlayEffectAudio("error_" + id).then(() => {
                          setCurrentPlayId("");
                        });
                      }}
                    >
                      <img
                        className="icon_play_normal"
                        src={
                          currentPlayId == "error_" + id
                            ? icon_play_selected
                            : icon_play_normal
                        }
                      ></img>
                      <audio
                        id={"error_" + id}
                        src={input_answer_url}
                        preload="auto"
                      ></audio>
                    </div>
                  ) : (
                    ""
                  )}

                  {type == 1 && input_answer_url && (
                    <div className="error_ans_img">
                      <img className="ans_img" src={input_answer_url}></img>
                    </div>
                  )}
                </div>
              )}
              {(subjective || !input_correct) &&
                (correct_answer || correct_answer_url) && (
                  <div>
                    {subjective ? "参考答案：" : "正确答案："}
                    {correct_answer_url ? (
                      ""
                    ) : (
                      <div style={{ display: "inline", color: "#66d167" }}>
                        {correct_answer}
                      </div>
                    )}

                    {type == 2 && correct_answer_url ? (
                      <div
                        className="back_tari_course_connect"
                        onClick={() => {
                          setCurrentPlayId("correct_" + id);

                          promisifiedPlayEffectAudio("correct_" + id).then(
                            () => {
                              setCurrentPlayId("");
                            }
                          );
                        }}
                      >
                        <img
                          className="icon_play_normal"
                          src={
                            currentPlayId == "correct_" + id
                              ? icon_play_selected
                              : icon_play_normal
                          }
                        ></img>
                        <audio
                          id={"correct_" + id}
                          src={correct_answer_url}
                          preload="auto"
                        ></audio>
                      </div>
                    ) : (
                      ""
                    )}
                    {type == 1 && correct_answer_url && (
                      <div className="correct_ans_img">
                        <img className="ans_img" src={correct_answer_url}></img>
                      </div>
                    )}
                  </div>
                )}
            </div>
          </div>

          {(hint || materials) && !input_correct && (
            <div className="back_tari_course_connect_bottom">
              <div className="style_line">
                <div
                  style={{
                    height: 15,
                    borderBottom: "1px dashed #FFCD01",
                  }}
                ></div>
              </div>
              {materials && !input_correct && (
                <div
                  className="style_ans_analysis"
                  style={{ marginBottom: 20 }}
                >
                  <div className="style_ans_title">积累的素材</div>
                  <div
                    className="style_ans_connect"
                    style={{ marginBottom: 0 }}
                  >
                    <div
                      className="markdown_content"
                      // source={hint}
                      // escapeHtml={false}
                    >
                      {renderMaterials(materials)}
                      {/* {material_title} */}
                    </div>
                  </div>
                </div>
              )}

              {hint && !input_correct && (
                <div className="style_ans_analysis">
                  <div className="style_ans_title">答案解析</div>
                  <div
                    className="style_ans_connect"
                    style={{ marginBottom: 0 }}
                  >
                    <ReactMarkdown
                      className="markdown_content"
                      source={hint}
                      escapeHtml={false}
                    ></ReactMarkdown>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </>
    </div>
  );
};
export const KbItem = (props) => {
  console.log("kb item props is: ", props);
  const [showQuizzes, setShowQuizzes] = useState(false);
  return (
    <div className="kb-lessons" style={{ marginTop: props.index ? "" : 0 }}>
      <div
        className="kb-lesson"
        style={{ backgroundColor: showQuizzes ? "#FFFAED" : "" }}
      >
        <div className="kb-info">
          {props.name ? (
            <div className="kb-name" style={{ fontSize: 16 }}>
              {props.isBook ? "《" + props.name + "》" : props.name}
            </div>
          ) : (
            ""
          )}
          {props.description && (
            <div className="kb-desc">{props.description}</div>
          )}
        </div>
        <div
          className="kb-lesson-counts"
          style={{
            backgroundColor: props.wrong_count ? "#FFEFEF" : "#E4FFE4",
            color: props.wrong_count ? "#FF6868" : "#55C656",
          }}
          onClick={() => {
            setShowQuizzes((per) => !per);
          }}
        >
          <div className="kb-lesson-count">
            <div>{`共${
              (props.correct_count || 0) + (props.wrong_count || 0)
            }题`}</div>
            <div>
              {props.wrong_count ? "错" + props.wrong_count + "题" : "全对"}
            </div>
          </div>
          <img
            className={`kb-lessons-quiz ${showQuizzes ? "transfrom-img" : ""} `}
            src={icon_R2_down}
          ></img>
        </div>
      </div>
      {showQuizzes ? (
        <div className="kb-lesson-quizzes">
          <div className="kb-lesson-quizzes-border">
            <div className="quiz-items">
              {props.quizzes &&
                props.quizzes.map((itm, inx) => {
                  return (
                    <QuizItem
                      itm={itm}
                      inx={inx}
                      key={inx + "==" + "ques"}
                    ></QuizItem>
                  );
                })}
              {/* <QuizTitle index={1}>问题提莫</QuizTitle> */}
            </div>
          </div>
          <div
            className="quiz-no-open"
            style={{ marginTop: 0 }}
            onClick={() => {
              setShowQuizzes((per) => !per);
            }}
          >
            收起<img src={icon_R2_down} className="transfrom-img"></img>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default (props) => {
  let name = props.name;
  if (name) {
    name = name.split('：')[0];
  }
  return (
    <InfoBroadTitle isImg={false} titles={props.title ? props.title : "读后练习情况"}>
      <div>
        {props.reading_test &&
            <KbItem {...props.reading_test} name={name} index={0}></KbItem>
        }
      </div>
    </InfoBroadTitle>
  );
};
