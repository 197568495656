import React, { useState } from "react";
import Word from "./Word";
import icon_R2_down from "R2/icon_R2_down.png";

import { InfoBroadTitle, Titles } from "../reading_details/ExaminationCommit";

export default (props) => {
  //每日报告，没有答案解析，有下拉错题（isReport==true）
  const [collapsed, setCollapsed] = useState(true);
  const renderHeaderForCollapsedMode = () => {
    let correct_count = 0;
    let wrong_count = 0;
    if (props.sub_subjects) {
      props.sub_subjects.forEach(subject => {
        correct_count += subject.correct_count;
        wrong_count += subject.wrong_count;
      });
    }

    return (
      <div
        className="kb-lesson"
        style={{ backgroundColor: collapsed ? "#FFFAED" : "" }}
      >
        <div className="kb-info">
          <div className="kb-name" style={{ fontSize: 16 }}>
            {props.name}
          </div>
          {props.description && (
            <div className="kb-desc">{props.description}</div>
          )}
        </div>
        <div
          className="kb-lesson-counts"
          style={{
            backgroundColor: wrong_count ? "#FFEFEF" : "#E4FFE4",
            color: wrong_count ? "#FF6868" : "#55C656",
          }}
          onClick={() => {
            setCollapsed((per) => !per);
          }}
        >
          <div className="kb-lesson-count">
            <div>{`共${
              (correct_count || 0) + (wrong_count || 0)
            }题`}</div>
            <div>
              {wrong_count ? "错" + wrong_count + "题" : "全对"}
            </div>
          </div>
          <img
            className={`kb-lessons-quiz ${collapsed ? "" : "transfrom-img"} `}
            src={icon_R2_down}
          ></img>
        </div>
      </div>
    )
  };

  const renderFooterForCollapsedMode = () => {
    if (!collapsed) {
      return (
        <div
          className="quiz-no-open"
          onClick={() => {
            setCollapsed((per) => !per);
          }}
        >
          收起<img src={icon_R2_down} className="transfrom-img"></img>
        </div>
      )
    }
  };

  const renderContent = () => {
    return (
      <div style={{ padding: props.use_collapsed_mode ? '0 10px' : '10px' }}>
        {props.sub_subjects && props.sub_subjects.length > 0
          ? props.sub_subjects.map((itm, idx) => {
              return (
                <Word
                  key={"materials" + idx}
                  type={itm.material_type == 3 ? 1 : 0}
                  name={itm.name}
                  materials={[...itm.materials]}
                  material_index={idx}
                  isReport={true}
                  correct_count={itm.correct_count}
                  wrong_count={itm.wrong_count}
                  quizzes={itm.quizzes}
                  // name={material_item.name}
                  // materials={material_item.materials}
                  // material_index={material_index}
                ></Word>
              );
            })
          : ""}
      </div>
    );
  };

  return (
    <InfoBroadTitle isImg={false} titles={"好词好句积累"}>
      <div className="kb-lessons">
        {props.use_collapsed_mode && renderHeaderForCollapsedMode()}
        {props.use_collapsed_mode && !collapsed && renderContent()}
        {!props.use_collapsed_mode && renderContent()}
        {props.use_collapsed_mode && renderFooterForCollapsedMode()}
      </div>
    </InfoBroadTitle>
  );
};
